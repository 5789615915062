import React from "react";
import "./App.css";

import { Switch, Route, BrowserRouter } from "react-router-dom";

import SolicitaVacuna from "./pages/SolicitaVacuna";
import ConsultaCertificados from "./pages/ConsultaCertificados";
import ConsultaVacuna from "./pages/ConsultaVacuna.js";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import Reset from "./pages/Reset";
import { auth } from "./firebase";
import PageLoading from "./components/PageLoading";
import ConsultaVacunaDetail from "./pages/ConsultaVacunaDetail";

function App() {
  const [firebaseUser, setFirebaseUser] = React.useState(false);

  React.useEffect(() => {
    auth.onAuthStateChanged((user) => {
      console.log(user);
      if (user) {
        setFirebaseUser(user);
      } else {
        setFirebaseUser(null);
      }
    });
  }, []);

  return firebaseUser !== false ? (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/solicitaVacuna" component={SolicitaVacuna} />
          <Route
            exact
            path="/consultaCertificados"
            component={ConsultaCertificados}
          />
          <Route
            exact
            path="/consultaVacuna"
            component={ConsultaVacuna}
          />
              <Route
                  path="/consultaVacuna/:vacunaId/detail"
                  exact
                  component={ConsultaVacunaDetail}
                />
          <Route path="/reset">
            <Reset />
          </Route>
          <Route exact path="/Home" component={Home} />
          <Home />

          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </>
  ) : (
    <PageLoading />
  );
}

export default App;
