import React from "react";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import InputSearch from "../components/InputSearch";
import "./styles/ConsultaCertificados.css";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { withStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import { firebase } from "../firebase";
import moment from "moment";


const columns = [
  {
    field: "id",
    hide: true,
    identity: true,
  },
  {
    field: "certificate",
    headerName: "# Certificado",
     width: 200
  },
  {
    field: "province",
    headerName: "Provincia",
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "canton",
    headerName: "Cantón",
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "parish",
    headerName: "Parroquia",
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "ownerId",
    headerName: "Identificación",
    width: 180,
 

  },
  {
    field: "ownerName",
    headerName: "Nombre",
    width: 180,

  },
  {
    field: "animals",
    headerName: "Animales",
    width: 180,
    type: "number",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "date",
    headerName: "Vacunado",
    type: "date",
    width: 180,
 
  },
  {
    field: "date_caducidad",
    headerName: "Caduca",
    type: "date",
    width: 180,
  },
  {
    field: "date_movilizar",
    headerName: "Movilizar desde",
    type: "date",
    width: 180,
  },
];

const styles = (theme) => ({
  root: {
    fontSize: "1.6rem",
    borderRadius: 25,
    backgroundColor: "white",
    border: "1px solid rgba(155, 155, 155, 1)",

    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 600,
    },
    "& .MuiDataGrid-columnsContainer": {
      borderBottom: "1px solid rgba(155, 155, 155, 1)",
    },
    "& .MuiDataGrid-row": {
      borderBottom: "2px solid rgba(155, 155, 155, 1)",
    },
  },
});

class ConsultaCertificados extends React.Component {
  state = {
    search: "",
    result: [{}],
    loading: false,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      loading: false,
    });

    if (!this.state.search.trim()) {
      this.setState({
        error: "Ingrese número de cédula para continuar",
      });
      return;
    }
    this.handleCallback(this.state.search);
  };



  handleCallback = async (search) => {
    try {
      const db = firebase.firestore();

      const data = await db
        .collection("certificates")
        .where("ownerId", "==", search)
        .get();

      const arrayData = data.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(arrayData);

      let newData = arrayData.map(function (certificate) {
        const date = moment(certificate.vaccination.toDate().toString()).format(
          "YYYY/MM/DD"
        );

        let dateCaducidad = new Date(date);
        let dateMovilizar = new Date(date);
        let parroquia = "";
        let nombre = "";
        if (certificate.parish) {
          parroquia = certificate.parish;
        }

        if (certificate.ownerName) {
          nombre = certificate.ownerName;
        }

        return {
          certificate: certificate.certificate,
          province: certificate.province,
          canton: certificate.canton,
          parish: parroquia,
          id: certificate.id,
          ownerId: certificate.ownerId,
          ownerName: nombre,
          animals: certificate.animals,
          date: date,
          date_caducidad: moment(dateCaducidad.setDate(dateCaducidad.getDate() + 150)).format(
            "YYYY/MM/DD"
          ),
          date_movilizar: moment(dateMovilizar.setDate(dateMovilizar.getDate() + 16)).format(
            "YYYY/MM/DD"
          ),
        };
      });

      this.setState({
        result: newData,
        loading: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="container-fluid">
        <div className="header">
          <Link to="/" className="btn btn-link btn-inicio">
            <AiIcons.AiOutlineArrowLeft /> <span>Inicio</span>
          </Link>{" "}
        </div>
        <div className="body">
          <div className="wizard">
            <h1>Consulta Certificado</h1>
            <div>
              Visualiza la información de cada certificado ingresando tu <br />{" "}
              número de cédula
            </div>
            <InputSearch
              onSubmit={this.handleSubmit}
              onChange={this.handleChange}
              value={this.state.search}
            ></InputSearch>
            {this.state.loading !== true ? (
              <div className="indicaciones">
                <InsertDriveFileIcon style={{ fontSize: 60 }} />
                <h2>
                  Todavía no has iniciado ninguna búsqueda. Ingresa tu <br />{" "}
                  número de cédula
                </h2>
              </div>
            ) : (
              <div
                style={{
                  height: 500,
                  width: "100%",
                  padding: "0px 25px",
                  marginTop: 15,
                }}
              >
                <DataGrid
                  rows={this.state.result}
                  columns={columns}
                  pageSize={10}
                  checkboxSelection
                  className={classes.root}
                  sortModel={[
                    {
                      field: "date",
                      sort: "desc",
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ConsultaCertificados);
