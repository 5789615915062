import React from "react";
import "./styles/NotFound.css";
import imgNotFound from "../components/images/notfound.png";
function NotFound() {
  return (
    <React.Fragment>
      <div className="container-notFoud">
        <img src={imgNotFound} alt="Imagen pagina no encontrada 404"></img>
        <h1>404: Not Found</h1>;
      </div>
    </React.Fragment>
  );
}

export default NotFound;
