import React from "react";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { makeStyles } from "@material-ui/core/styles";
import InputSearch from "../components/InputSearch";
import { DataGrid } from "@material-ui/data-grid";
import * as FaIcons from "react-icons/fa";
import "./styles/CertificadoReporte.css";
import { firebase } from "../firebase";
import moment from "moment";
import utf8 from "utf8";

const columns = [
  {
    field: "id",
    hide: true,
    identity: true,
  },
  {
    field: "certificate",
    headerName: "Número de Certificado",
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "province",
    headerName: "Provincia",
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "canton",
    headerName: "Cantón",
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "parish",
    headerName: "Parroquia",
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "ownerId",
    headerName: "Identificación",
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "ownerName",
    headerName: "Nombre",
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "animals",
    headerName: "# Productos Vacunados",
    flex: 1,
    type: "number",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "date",
    headerName: "Fecha Vacunación",
    type: "date",
    flex: 1,
    vaccination: "super-app-theme--header",
  },
];

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    backgroundColor: "#148d74",
    color: "white",
  },
  modal: {
    borderRadius: 20,
    width: "100%",
    maxWidth: "700px",
  },

  content: {
    padding: "30px",
  },
  icon: {
    color: "white",
  },
}));

const stylesTable = makeStyles({
  root: {
    fontSize: "1.6rem",
    borderRadius: 25,
    backgroundColor: "white",
    border: "1px solid rgba(155, 155, 155, 1)",

    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 600,
    },
    "& .MuiDataGrid-columnsContainer": {
      borderBottom: "1px solid rgba(155, 155, 155, 1)",
    },
    "& .MuiDataGrid-row": {
      borderBottom: "2px solid rgba(155, 155, 155, 1)",
    },
  },
});

function CertificadoReporte() {
  const classes = useStyles();

  const [search, setSearch] = React.useState("");
  const [result, setResult] = React.useState([{}]);
  const [loading, setLoading] = React.useState(false);
  const classesTable = stylesTable();

  const handleChange = (e) => {
    setSearch(e.target.value.toString());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading({
      loading: false,
      error: null,
    });

    if (!search.trim()) {
      setLoading(false);
      return;
    }
    handleCallback(search);
  };

  const handleCallback = async (id) => {
    try {
      const db = firebase.firestore();

      const data = await db
        .collection("certificates")
        .where("ownerId", "==", id)
        .get();

      const arrayData = data.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(arrayData);

      let newData = arrayData.map(function (certificate) {
        const date = moment(certificate.vaccination.toDate().toString()).format(
          "YYYY/MM/DD"
        );
        let parroquia = "";
        let nombre = "";
        if (certificate.parish) {
          parroquia = certificate.parish;
        }

        if (certificate.ownerName) {
          nombre = certificate.ownerName;
        }

        return {
          certificate: certificate.certificate,
          province: certificate.province,
          canton: certificate.canton,
          parish: parroquia,
          id: certificate.id,
          ownerId: certificate.ownerId,
          ownerName: nombre,
          animals: certificate.animals,
          date: date,
        };
      });

      setResult(
        newData
      );

      setLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div className="asigna-main">
        <div className="nav-asigna">
          <div className="asigna-encabezado">
            <h1 className={classes.icon}>
              <FaIcons.FaSearch aria-label="Document" /> Consulta Certificados
            </h1>
          </div>
        </div>

        <div className="body">
          <div className="wizard">
            <h1>Consulta Certificado</h1>
            <div>
              Visualiza la información de cada certificado ingresando tu <br />{" "}
              número de cédula
            </div>
            <InputSearch
              onSubmit={handleSubmit}
              onChange={handleChange}
              value={search}
            ></InputSearch>
            {loading !== true ? (
              <div className="indicaciones">
                <InsertDriveFileIcon style={{ fontSize: 60 }} />
                <h2>
                  Todavía no has iniciado ninguna búsqueda. Ingresa tu <br />{" "}
                  número de cédula
                </h2>
              </div>
            ) : (
              <div
                style={{
                  height: 500,
                  width: "100%",
                  padding: "0px 25px",
                  marginTop: 15,
                }}
              >
                <DataGrid
                  rows={result}
                  columns={columns}
                  pageSize={10}
                  checkboxSelection
                  className={classesTable.root}
                  sortModel={[
                    {
                      field: "date",
                      sort: "desc",
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CertificadoReporte;
