import React from "react";
import { Link } from "react-router-dom";
import * as GiIcons from "react-icons/gi";
import "date-fns";
import { makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { DataGrid, GridToolbarContainer,
  GridToolbarExport, } from "@material-ui/data-grid";
import { firebase } from "../firebase";
import moment from 'moment'
import './styles/EstadoVacuna.css'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "inline-block",
    alignItems: "center",
    width: "40%",
    margin: "25px 0px 25px 50px",
    borderRadius: 30,
    border: "1px solid #A9A9A9",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: "1.8rem",
  },
  iconButton: {
    padding: 5,
    fontSize: 20,
  },
  button:{
    color:"#148d74",
    fontSize:12
  },
  container:{
    marginLeft:15,
  },

  divider: {
    height: 28,
    margin: 4,
  },
  modalHeader: {
    backgroundColor: "#148d74",
    color: "white",
  },
  modal: {
    borderRadius: 20,
    width: "100%",
    maxWidth: "700px",
  },
  reference: {
    color: "black",
    fontWeight: 600,
    fontSize: "16px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  select: {
    fontSize: "14px",
  },
  content: {
    padding: "30px",
  },
  datepicker: {
    width: "100%",

    "& .MuiFormLabel-root": {
      fontSize: "17px !important",
    },
    "& .MuiInputBase-root": {
      fontSize: "14px !important",
      borderColor: "#1B9E85",
    },
  },
}));

const stylesTable = makeStyles({
  root: {
    fontSize: "1.6rem",
    borderRadius: 25,
    backgroundColor: "white",
    border: "1px solid rgba(155, 155, 155, 1)",

    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 600,
    },
    "& .MuiDataGrid-columnsContainer": {
      borderBottom: "1px solid rgba(155, 155, 155, 1)",
    },
    "& .MuiDataGrid-row": {
      borderBottom: "2px solid rgba(155, 155, 155, 1)",
    },
  },
});

function EstadosVacuna(props) {
  const [rows, setRows] = React.useState([
    {
      id: 0,
    },
  ]);

  const classes = useStyles();
  const classesTable = stylesTable();

  React.useEffect(() => {
    const obtenerDatos = async () => {
      const db = firebase.firestore();
      try {
        let data = {};
        if (props.coordinator.type === "coordinator") {
          data = await db
            .collection("vaccinationRequests")
            .where("location.province", "==", props.coordinator.province)
            .get();
        } else {
          data = await db.collection("vaccinationRequests").get();
        }


        const arrayData = data.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(arrayData);

        let newData = arrayData.map(function (vacuna) {

          let datePlaned='';
          if(vacuna.detail?.planed){
            datePlaned=moment(vacuna.detail.planed.toDate().toString()).format('YYYY/MM/DD')
          }
          return {
            productor: vacuna.owner.name,
            idProfuctor: vacuna.ownerId,
            animals: vacuna.animals,
            id: vacuna.id,
            brigadista:vacuna.responsable?.name,
            fecha:datePlaned,
            reference:vacuna.serie,
            estado: {
              status:vacuna.status,
              id:vacuna.id
            },
          };
        });

        setRows(newData);
        console.log(newData)
      } catch (error) {
        console.log(error);
      }
    };
    obtenerDatos();
  }, [props]);

  const columns = [
    {
      field: "id",
      hide: true,
      identity: true,
    },
    {
      field: "reference",
      headerName: "Referencia",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "productor",
      headerName: "Productor",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "idProfuctor",
      headerName: "Id Productor",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "animals",
      headerName: "Animales",
      type: "number",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "fecha",
      headerName: "Fecha",
      type: "date",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "brigadista",
      headerName: "Brigadista",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "estado",
      headerName: "Estado",
      flex: 1,
      width: 300,
      renderCell: (params) => {

   

        if(params.value){
          if(params.value.status===0){
            return (      
              <Link to={`/estadosVacuna/${params.value.id}/detail`} className='btn btn-warning'   style={{ width: '100%',backgroundColor:'#FF5722'}}> Pendiente</Link>
            )
          }
          if(params.value.status===1){
            return (      
              <Link to={`/estadosVacuna/${params.value.id}/detail`} className='btn btn-primary' style={{ width: '100%',backgroundColor:'#03A9F4'}}> Asignado</Link>
            )
          }
          if(params.value.status===2){
            return (      
              <Link to={`/estadosVacuna/${params.value.id}/detail`} className='btn btn-secondary' style={{ width: '100%',backgroundColor:'#673AB7'}}> Reasignado</Link>
            )
          }
          if(params.value.status===3){
            return (      
              <Link to={`/estadosVacuna/${params.value.id}/detail`} className='btn btn-success' style={{ width: '100%',backgroundColor:'#8BC34A'}}> Finalizado</Link>
            )
          }
          if(params.value.status===4){
            return (      
              <Link to={`/estadosVacuna/${params.value.id}/detail`} className='btn btn-success' style={{ width: '100%',backgroundColor:'#F44336'}}> Finalizado sin Vacunar</Link>
            )
          }
         
            return (      
              <div></div>
            )
          
        }

       
      },
      disableClickEventBubbling: true,

    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={classes.container}>
        <GridToolbarExport className={classes.button}/>
      </GridToolbarContainer>
    );
  }



  return (
    <React.Fragment>
      <div className="asigna-main">
        <div className="nav-asigna">
          <div className="asigna-encabezado">
            <h1>
              <GiIcons.GiMedicalThermometer aria-label="Vacunación" /> Ver
              Vacunación
            </h1>

            <Paper component="form" className={classes.root}>
              <IconButton
                type="submit"
                className={classes.iconButton}
                aria-label="search"
              >
                <SearchIcon style={{ fontSize: 30 }} />
              </IconButton>
              <InputBase className={classes.input} placeholder="Buscar" />
            </Paper>
          </div>
        </div>
        <div
          style={{
            height: 500,
            width: "100%",
            padding: "0px 50px",
            marginTop: '-57px'
         
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            checkboxSelection
            className={classesTable.root}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default EstadosVacuna;
