import React from "react";
import { Link } from "react-router-dom";
import "./styles/LoginForm.css";
import {auth} from '../firebase'

class LoginForm extends React.Component {


  state = {
    email:'',
    password:'',
    error:null,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form was submitted");

    if(!this.state.email.trim()){
      this.setState({
        'error': 'Ingrese un email válido',
      });
      return
    }

    if(!this.state.password.trim()){
      this.setState({
        'error': 'Ingrese una contraseña',
      });
    
      return
    }

    if(this.state.password.length<6){
      this.setState({
        'error': 'Ingrese una contraseña válida mayor a 6 caracteres',
      });
      return
    }
    this.handleCallback();

  };


  handleCallback = async () => {
    try {
      const res = await auth.signInWithEmailAndPassword(this.state.email, this.state.password)
      console.log(res.user)
      this.setState({
        'error': null,
        'email':'',
        'password':''
      });
  
      this.props.history.push('/Home');
  } catch (error) {
      console.log(error)
      if(error.code === 'auth/invalid-email'){
        this.setState({
          'error': 'Email no válido',
        });
       
      }
      if(error.code === 'auth/user-not-found'){
        this.setState({
          'error': 'Usuario no registrado',
        });
         
      }
      if(error.code === 'auth/wrong-password'){
        this.setState({
          'error': 'Contraseña incorrecta',
        });
        
      }
  }
  };
  
  render() {
    return (
    <div className="form-login">  
        <h1 className="title-login">Ingresa tu Cuenta</h1>

        <form onSubmit={this.handleSubmit}>
          {
            this.state.error && (
              <div className='alert alert-danger'> {this.state.error} </div>
            )
          }
          <div className="textOnInput">
            <label htmlFor="email">Email * </label>
            <input
              onChange={this.handleChange}
              className="form-control "
              type="email"
              name="email"
              value={this.state.email}
            />
          </div>
          <div className="textOnInput">
            <label htmlFor="password">Contraseña * </label>
            <input
              onChange={this.handleChange}
              className="form-control"
              type="password"
              name="password"
              value={this.state.password}
            />
          </div>
         
         
       
             
                <div className="link-contra">
                  <button type="button" className="btn btn-link" onClick={() => this.props.history.push('/reset')}>
                    ¿Olvidaste la Contraseña?
                  </button>
                </div>
       
         
       
          <div className="btn-inicio">
            <button type="submit" className="btn btn-primary">
              INICIAR SESIÓN
            </button>
          </div>
        </form>
        <div className="separador">
          <hr />
          <span>O</span>
          <hr />
        </div>

        <div className="btn-vacuna">
          <Link to="/solicitaVacuna" className="btn btn-primary">
            SOLICITAR VACUNACIÓN
          </Link>
        </div>

        <div className="btn-certificados">
          <Link to="/consultaCertificados" className="btn btn-primary">
            CONSULTAR CERTIFICADOS
          </Link>
        </div>
        <div className="btn-consulta-vacuna">
          <Link to="/consultaVacuna" className="btn btn-primary">
            CONSULTAR PEDIDO DE VACUNACIÓN
          </Link>
        </div>
      </div>
    );
  }
}

export default LoginForm;
