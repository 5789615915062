import React from "react";
import {  Container } from "react-bootstrap";
import "./styles/Login.css";
import logoAspe from "../components/images/log-aspe.png";
import LoginForm from "../components/LoginForm";


function Login(props) {
  return (
    <Container fluid>
      <div className="row">
        <div className="col-sm-8">
          <div className="bienvenida">
            <div className="logo">
              <img
                className="logo-aspe-inicio"
                src={logoAspe}
                alt="Logo empresa ASPE"
              ></img>
            </div>

            <div className="titulo">
              Bienvenido  a la  Aplicación PP
            </div>

            <div className="subtitulo">
              Administración de pedidos de vacunación, visualización de
              certificados y consolidación de información de ASPE y
              AGROCALIDAD.
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          
        <LoginForm history={props.history}></LoginForm>
        
        </div>
      </div>
    </Container>
  );
}

export default Login;
