import React from "react";
import "./styles/SolicitaVacuna.css";
import * as AiIcons from "react-icons/ai";
import Steps from "../components/Steps";
import { Link } from "react-router-dom";
import { firebase } from "../firebase";
import Swal from 'sweetalert';




class SolicitaVacuna extends React.Component {
  


  alertaFaltanDatos(faltantes) {
    Swal({
        title: 'Alto ahi!',
        text: `Te faltan campos por rellenar 🧐\n ${faltantes}`,
        icon: 'error'
    });
}

alertaError(){
    Swal({
        title: 'Opps!',
        text: `Ha ocurrido algo inesperado 😅, vuelve a intentarlo nuevamente`,
        icon: 'error'
    });

    // Controlar cuando sea un error 500 para que mande un mensaje que los server estan caidos o algo
}

alertaExitosa() {
    Swal({
        title: 'Pedido Asignado',
        text: ' El pedido de vacunación se ha enviado correctamente. Pronto nos pondremos en contacto para informarte sobre la  cita de vacunación',
        icon: 'success'
    }).then((result) => {
        if (result.value || !result.value) {
            this.props.history.push('/');
        }
    });
}

  
  state = {
    loading:false,
    error:null,
    estadoCedula:false,
    form: {
      name: "",
      cedula: "",
      phone: "",
      provincia: "",
      canton:"",
      parroquia:"",
      address: "",
      numVacuna: "",
      indica: "",
    },
  };

  handleChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  cedulaIncorrecta = (e) => {
    this.setState({ estadoCedula: false});
  };

  cedulaCorrecta = (e) => {
    this.setState({ estadoCedula: true});
  };

  handleSubmit = async e => {

    e.preventDefault();

    this.setState({ loading: true, error: null });

    try {
 
   

      if(!this.state.form.name){
        this.alertaFaltanDatos('Ingresa Nombre');
        return;   
      }

      if(!this.state.form.phone){
        this.alertaFaltanDatos('Ingresa Teléfono');
        return;   
      }

      if(!this.state.form.numVacuna){
        this.alertaFaltanDatos('Ingresa # Animales a Vacunar');
        return;   
      }

      if(!this.state.form.provincia){
        this.alertaFaltanDatos('Ingresa Provincia');
        return;   
      }

      if(!this.state.form.canton){
        this.alertaFaltanDatos('Ingresa Canton');
        return;   
      }

      if(!this.state.form.parroquia){
        this.alertaFaltanDatos('Ingresa Parroquia');
        return;   
      }

      if(!this.state.form.address){
        this.alertaFaltanDatos('Ingresa Dirección');
        return;   
      }

      const db=firebase.firestore()
      const vacunaRequest={
        animals:parseInt(this.state.form.numVacuna),
    
        ownerId:this.state.form.cedula,
        location:{
          province:this.state.form.provincia,
          address:this.state.form.address,
          canton:this.state.form.canton,
          parish:this.state.form.parroquia,
        },
        owner:{
          name:this.state.form.name,
          phone:this.state.form.phone
        
        }
      }

      await db.collection('vaccinationRequests').add(vacunaRequest);

      this.setState({ loading: false });
      this.alertaExitosa();
    } catch (error) {
      this.setState({ loading: false, error: error });
      this.alertaError();
    }
  };

  render() {


    return (
      <div className="container-fluid">
        <div className="header">
          <Link to="/" className="btn btn-link btn-inicio">
            <AiIcons.AiOutlineArrowLeft /> <span>Inicio</span>
          </Link>{" "}
        </div>
        <div className="body">
          <div className="wizard">
            <h1>Solicitar Vacunación</h1>
            <div>
              Llene todos los datos a continuación para agendar un pedido  <br />{" "}
              de vacunación
            </div>
            <div className="steps">
              <Steps
                onChange={this.handleChange}
                onSubmit={this.handleSubmit}
                formValues={this.state.form}
                cedulaIncorrecta={this.cedulaIncorrecta}
                cedulaCorrecta={this.cedulaCorrecta}
                estadoCedula={this.state.estadoCedula}
              ></Steps>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SolicitaVacuna;
