import React from "react";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import InputSearch from "../components/InputSearch";
import "./styles/ConsultaCertificados.css";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { withStyles  } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import { firebase } from "../firebase";
import moment from "moment";

const columns = [
  {
    field: "id",
    hide: true,
    identity: true,
  },
  {
    field: "serie",
    headerName: "Referencia",
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "status",
    headerName: "Estado",
    flex: 1,
    headerClassName: "super-app-theme--header",
    renderCell: (params) => {

      if(params.value){
        if(params.value===0){
          return (      
            <div> Pendiente</div>
          )
        }
        if(params.value===1){
          return (  
            <div> Asignado</div>    
         
          )
        }
        if(params.value===2){
          return (   
            <div> Reasignado</div>      
      
          )
        }
        if(params.value===3){
          return (      
            <div> Terminado</div>    

          )
        }
        if(params.value===4){
          return (   
            <div> Terminado sin Vacunar</div>       
          )
        }
        if(params.value>5){
          return (      
            <div></div>
          )
        }
      }

     
    },
  },
  {
    field: "date",
    headerName: "Fecha Planificada",
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "hour",
    headerName: "Hora Planificada",
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "productor",
    headerName: "Productor",
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
 
  {
    field: "animals",
    headerName: "# Productos Vacunados",
    flex: 1,
    type: "number",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "vacunador",
    headerName: "Vacunador Asignado",
    flex: 1,
    vaccination: "super-app-theme--header",
  },
  {
    field: "options",
    headerName: "Opciones",
    flex: 1,
    vaccination: "super-app-theme--header",
    renderCell: (params) => {
      console.log(params.value)
      return (      
        <Link to={`/consultaVacuna/${params.value}/detail`} className='btn btn-secondary'   style={{ width: '100%'}}> Detalle</Link>
      )
    

     
    },
  },
];

const styles = theme => ({
  root: {
    fontSize: "1.6rem",
    borderRadius: 25,
    backgroundColor: "white",
    border: "1px solid rgba(155, 155, 155, 1)",

    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 600,
    },
    "& .MuiDataGrid-columnsContainer": {
      borderBottom: "1px solid rgba(155, 155, 155, 1)",
    },
    "& .MuiDataGrid-row": {
      borderBottom: "2px solid rgba(155, 155, 155, 1)",
    },
  },
});

class ConsultaVacuna extends React.Component {
  state = {
    search: "",
    result: [{}],
    loading: false,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      loading: false,
    });

    if (!this.state.search.trim()) {
      this.setState({
        error: "Ingrese número de cédula para continuar",
      });
      return;
    }
    this.handleCallback(this.state.search);
  };

  handleCallback = async (search) => {
    try {
      const db = firebase.firestore();

      const data = await db
        .collection("vaccinationRequests")
        .where("ownerId", "==", search)
        .get();

      const arrayData = data.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(arrayData);

      let newData = arrayData.map(function (request) {
        let assign='';
        if(request.detail.assign){
          assign = moment(request.detail.assign.toDate().toString()).format(
            "YYYY/MM/DD"
          );
        }

        return {
          id: request.id,
          serie: request.serie,
          status: request.status,
          date: assign,
          hour: request.detail.range,
          productor: request.owner.name,
          animals: request.animals,
          vacunador: request.responsable.name,
          options:request.id
          
        };
      });

      this.setState({
        result: newData,
        loading: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="container-fluid">
        <div className="header">
          <Link to="/" className="btn btn-link btn-inicio">
            <AiIcons.AiOutlineArrowLeft /> <span>Inicio</span>
          </Link>{" "}
        </div>
        <div className="body">
          <div className="wizard">
            <h1>Consulta Vacunación</h1>
            <div>
              Visualiza la información de cada solicitud ingresando tu <br />{" "}
              número de cédula
            </div>
            <InputSearch
              onSubmit={this.handleSubmit}
              onChange={this.handleChange}
              value={this.state.search}
            ></InputSearch>
            {this.state.loading !== true ? (
              <div className="indicaciones">
                <InsertDriveFileIcon style={{ fontSize: 60 }} />
                <h2>
                  Todavía no has iniciado ninguna búsqueda. Ingresa tu <br />{" "}
                  número de cédula
                </h2>
              </div>
            ) : (
              <div
                style={{
                  height: 500,
                  width: "100%",
                  padding: "0px 25px",
                  marginTop: 15,
                }}
              >
                <DataGrid
                  rows={this.state.result}
                  columns={columns}
                  pageSize={10}
                  checkboxSelection
                  className={classes.root}
                  sortModel={[
                    {
                      field: "date",
                      sort: "desc",
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ConsultaVacuna);
