import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles({
  root: {
    minWidth: 150,
    borderRadius: "19px",
    width: "100%",
  },

  title: {
    fontSize: 16,
    fontWeight: 600,
    color: "#868686",
  },
  indicador: {
    fontSize: 78,
    fontWeight: 600,
    color: "#2196F3",
    textAlign: "center",
  },

  label: {
    fontSize: 18,
    fontWeight: 600,
    color: "#2196F3",
    textAlign: "center",
  },


  default: {
    color: "#707070",
  },
  label2: {
    fontSize: 16,

    color: "#868686",
    textAlign: "center",
  },

});

const dias = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];

const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Dicimbre",
];

function Clock() {
  const classes = useStyles();
  const hoy = new Date();
  let time = hoy.toLocaleTimeString();

  let dia = hoy.getDay();
  const nombreDia = dias[dia];

  const month = hoy.getMonth();
  const nameMonth = months[month];

  const anio = hoy.getFullYear();
  const day = hoy.getDate();

  const [ctime, setcTime] = React.useState(time);


    

  React.useEffect(() => {

    let timer = setInterval(() => {
      setcTime(new Date().toLocaleTimeString());
  
    }, 1000);
  
    return () => {
      clearInterval(timer);
    }
  }, [])


  return (
    <React.Fragment>
      <Card className={classes.root}>
        <CardContent>
          <Typography 
            className={classes.title}
            color="textSecondary"
            gutterBottom
      
          >
            {nombreDia}, {ctime}
          </Typography>
          <Typography className={`${classes.label} ${classes.default}`}  >
            {nameMonth}
          </Typography>
          <Typography className={`${classes.indicador} ${classes.default}`}>
            {day}
          </Typography>

          <Typography className={classes.label2}>{anio}</Typography>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default Clock;
