import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyD1ADx-Pry0pdNEWK7Dwt9ujLBPn6poXF0",
    authDomain: "aspe-312701.firebaseapp.com",
    projectId: "aspe-312701",
    storageBucket: "aspe-312701.appspot.com",
    messagingSenderId: "796472358297",
    appId: "1:796472358297:web:7ecc808cabd24e914a4f08",
    measurementId: "G-WPVCJN01QK"
  };

  firebase.initializeApp(firebaseConfig);
  const auth = firebase.auth();
  const storage=firebase.storage();

  export {firebase,auth,storage}