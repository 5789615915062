import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";



const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: '40%',
    margin: '25px auto',
    borderRadius:30,
    border: '1px solid #A9A9A9',

  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize:'1.8rem'
  },
  iconButton: {
    padding: 10,
    fontSize:20
  },


  divider: {
    height: 28,
    margin: 4,
  },
}));



export default function InputSearch(props) {
  const classes = useStyles();

  return (
    <Paper component="form" className={classes.root} onSubmit={props.onSubmit}>
      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search"
      >
        <SearchIcon style={{ fontSize: 30 }} />
      </IconButton>
      <InputBase
        
        className={classes.input}
        placeholder="Buscar"
        name="search"
        onChange={props.onChange}
        value={props.value}
      />
    </Paper>
  );
}
