import React from "react";
import './styles/Dropdown.css'

class Dropdown extends React.Component {
  constructor(props) {
	  console.log(props);
    super(props);
    this.state = {
      provincias: [],
      canton: [],
      parroquia: [],
      selectedProvincia: "--Seleccione Provincia--",
      selectedCanton: "--Seleccione Cantón--",
    };
    this.changeProvincia = this.changeProvincia.bind(this);
    this.changeCanton = this.changeCanton.bind(this);
  }

  componentDidMount() {
    this.setState({
      provincias: [
        {
          name: "Cotopaxi",
          canton: [
            {
              name: "La Maná",
              parroquia: [
                "El Carmen",
                "El Triunfo",
                "Guasaganda",
                "La Maná",
                "Pucayacu",
              ],
            },
            {
              name: "Latacunga",
              parroquia: [
                "11 de Noviembre (Ilinchisí)",
                "Alaques (Aláquez)",
                "Belisario Quevedo (Guanailín)",
                "Eloy Alfaro (San Felipe)",
                "Guaitacama (Guaytacama)",
                "Ignacio Flores (Parque Flores))",
                "Joseguango Bajo",
                "Juan Montalvo (San Sebastián)",
                "La Matriz",
                "Mulaló",
                "Poaló",
                "San Buenaventura",
                "San Juan de Pastocalle",
                "Tanicuchí",
                "Toacaso",
              ],
            },
            {
              name: "Pangua",
              parroquia: [
                "El Corazón",
                "Moraspungo",
                "Pinllopata",
                "Ramón Campaña",
              ],
            },
            {
              name: "Pujilí",
              parroquia: [
                "Angamarca",
                "Guangaje",
                "La Victoria",
                "Pilaló",
                "Pujilí",
                "Tingo",
                "Zumbahua",
              ],
            },
            {
              name: "Salcedo",
              parroquia: [
                "Antonio José Holgupin (Santa Lucía)",
                "Cusubamba",
                "Mulalillo",
                "Mulliquindil (Santa Ana)",
                "Pansaleo",
                "San Miguel",
              ],
            },
            {
              name: "Saquisilí",
              parroquia: ["Canchagua", "Chantilín", "Cochapamba", "PSaquisilí"],
            },
            {
              name: "Saquisilí",
              parroquia: [
                "Chugchillán",
                "Isinliví",
                "Las Pampas",
                "Palo Quemado",
                "Sigchos",
              ],
            },
          ],
        },

        {
          name: "Loja",
          canton: [
            {
              name: "Calvas",
              parroquia: [
                "Cariamanga",
                "Chile",
                "Colaisaca",
                "El Lucero",
                "San Vicente",
                "Sanguillín",
                "Utuana",
              ],
            },
            {
              name: "Catamayo",
              parroquia: [
                "Catamayo",
                "El Tambo",
                "San José",
                "San Pedro de la Bendita",
              ],
            },
            {
              name: "Celica",
              parroquia: [
                "Celica",
                "Cruzpamba",
                "Pozul",
                "Sabanilla",
                "Tnte. Maximiliano Rodríguez Loaiza",
              ],
            },
            {
              name: "Chaguarpamba",
              parroquia: [
                "Amarillos",
                "Buenavista",
                "Chaguarpamba",
                "El Rosario",
                "Santa Rufina",
              ],
            },
            {
              name: "Espíndola",
              parroquia: [
                "27 de Abril",
                "Amaluza",
                "Bellavista",
                "El Airo",
                "El Ingenio",
                "Jimbura",
                "Santa Teresita",
              ],
            },
            {
              name: "Gonzanamá",
              parroquia: [
                "Changaimina (La Libertad)",
                "Gonzanamá",
                "Nambacola",
                "Purunuma (Eguiguren)",
                "Sacapalca",
              ],
            },
            {
              name: "Loja",
              parroquia: [
                "Chantaco",
                "Chiquiribamba",
                "El Cisne",
                "El Sagrario",
                "Gualel",
                "Jimbilla",
                "Malacatos (Valladolid)",
                "Quinara",
                "San Lucas",
                "San Pedro de Vilcabamba",
                "San Sebastián",
                'Santiago "San Salvador o James"',
                "Sucre",
                "Taquil (Miguel Riofrío)",
                "Valle",
                "Vilcabamba (Victoria)",
                "Yangana (Arsenio Castillo)",
              ],
            },
            {
              name: "Macará",
              parroquia: [
                "General Eloy Alfaro (San Sebastián)",
                "La Victoria",
                "Larama",
                "Macará (Manuel Enríquez Rengel Suquilanda)",
                "Sabiango (La Capilla)",
              ],
            },
            { name: "Olmedo", parroquia: ["La Tingue", "Olmedo"] },
            {
              name: "Paltas",
              parroquia: [
                "Cangonamá",
                "Casanga",
                "Catacocha",
                "Guachanamá",
                "Lauro Guerrero",
                "Lourdes",
                "Orianga",
                "San Antonio",
                "Yamana",
              ],
            },
            {
              name: "Pindal",
              parroquia: ["12 de Diciembre", "Chaquinal", "Milagros", "Pindal"],
            },
            {
              name: "Puyango",
              parroquia: [
                "Alamor",
                "Ciano",
                "El Arenal",
                "El Limo (Mariana de Jesús)",
                "Mercadillo",
                "Vicentino",
              ],
            },
            {
              name: "Quilanga",
              parroquia: [
                "Fundochamba",
                "Quilanga",
                "San Antonio de Las Aradas",
              ],
            },
            {
              name: "Sozoranga",
              parroquia: ["Nueva Fátima", "Sozoranga", "Tacamoros"],
            },
            {
              name: "Saraguro",
              parroquia: [
                "El Paraíso de Celén",
                "El Tablón",
                "Lluzhapa",
                "Manú",
                "San Antonio de Qumbe (Cumbe)",
                "San Pablo de Tenta",
                "San Sebastián de Yúluc",
                "Saraguro",
                "Selva Alegre",
                "Sumaypamba",
                "Urdaneta (Paquishapa)",
              ],
            },
            {
              name: "Zapotillo",
              parroquia: [
                "Bolaspamba",
                "Garzareal",
                "Limones",
                "Mangahurco (Cazaderos)",
                "Paletillas",
                "Zapotillo",
              ],
            },
          ],
        },
        {
          name: "Pichincha",
          canton: [
            {
              name: "Cayambe",
              parroquia: [
                "Ascázubi",
                "Ayora",
                "Cangahua",
                "Cayambe",
                "Juan Montalvo",
                "Olmedo (Pesillo)",
                "Otón",
                "Santa Rosa de Cuzubamba",
              ],
            },
            {
              name: "Pedro Moncayo",
              parroquia: [
                "La Esperanza",
                "Malchinguí",
                "Tabacundo",
                "Tocachi",
                "Tupigachi",
              ],
            },
            {
              name: "Pedro Vicente Maldonado",
              parroquia: ["Pedro Vicente Maldonado"],
            },
            { name: "Puerto Quito", parroquia: ["Puerto Quito"] },
            {
              name: "Quito",
              parroquia: [
                "Alangasí",
                "Amaguaña",
                "Atahualpa (Habaspamba)",
                "Belisario Quevedo",
                "Calacalí",
                "Calderón (Carapungo)",
                "Carcelén",
                "Centro Histórico",
                "Chavezpamba",
                "Checa (Chilpa)",
                "Chilibulo",
                "Chillogallo",
                "Chimbacalle",
                "Cochapamba",
                "Comité del Pueblo",
                "Conocoto",
                "Cotocollao",
                "Cumbayá",
                "El Condado",
                "El Quinche",
                "Gualea",
                "Guamaní",
                "Guangopolo",
                "Guayllabamba",
                "Iñaquito",
                "Itchimbía",
                "Jipijapa",
                "Keneddy",
                "La Argelia",
                "La Concepción",
                "La Ecuatoriana",
                "La Ferroviaria",
                "La Libertad",
                "La Magdalena",
                "La Mena",
                "la Merced",
                "Llano Chico",
                "Lloa",
                "Mariscal sucre",
                "Nanegal",
                "Nanegalito",
                "Nayón",
                "Nono",
                "Pacto",
                "Perucho",
                "Pifo",
                "Píntag",
                "Pomasqui",
                "Ponceano ",
                "Puéllaro",
                "Puembo",
                "Puengasí",
                "Quitumbe",
                "Rumipamba",
                "San Antonio",
                "San Antonio de Minas",
                "San Bartolo",
                "San Isidro del Inca",
                "San José de Minas",
                "San Juan",
                "Tababela",
                "Tumbaco",
                "Turubamba",
                "Yaruquí",
                "Zámbiza",
              ],
            },
            {
              name: "Rumiñahui",
              parroquia: [
                "Cotogchoa",
                "Rumipamba",
                "San Pedro de Taboada",
                "San Rafael",
                "Sangolquí",
              ],
            },
            {
              name: "San Miguel de los Bancos",
              parroquia: ["Mindo", "San Miguel de los Bancos"],
            },
          ],
        },
        {
          name: "Santa Elena",
          canton: [
            { name: "La Libertad", parroquia: ["La Libertad"] },
            {
              name: "Salinas",
              parroquia: [
                "Anconcito",
                "Gral. Alberto Enríquez Gallo",
                "José Luis Tamayo (Muey)",
              ],
            },
            {
              name: "Santa Elena",
              parroquia: [
                "Atahualpa",
                "Ballenita",
                "Chanduy",
                "Colonche",
                "Manglaralto",
                "San José de Ancón",
                "Santa Elena",
                "Simón Bolívar (Julio Moreno)",
              ],
            },
          ],
        },
        {
          name: "Tungurahua",
          canton: [
            {
              name: "Ambato",
              parroquia: [
                "Ambatillo",
                "Atahualpa (Chisalata)",
                "Atocha - Ficoa",
                "Augusto N. Martínez (Mundugleo)",
                "Celiano Monge",
                "Constantino Fernández",
                "Cunchibamba",
                "Huachi Chico",
                "Huachi Grande",
                "Huachi Loreto",
                "Izamba",
                "Juan Benigno Vela",
                "la Merced",
                "La Península",
                "Matriz",
                "Montalvo",
                "Pasa",
                "Picaigua",
                "Pilagüín (Pilahuín)",
                "Pishilata",
                "Quisapincha (Quizapincha)",
                "San Bartolo de Pinllog",
                "San Fernando (Pasa San Fernando)",
                "San Francisco",
                "Santa Rosa",
                "Totoras",
                "Unamuncho",
              ],
            },
            {
              name: "Baños de Agua Santa",
              parroquia: [
                "Baños de Agua Santa",
                "Lligua",
                "Río Negro",
                "Río Verde",
                "Ulba",
              ],
            },
            { name: "Cevallos", parroquia: ["Cevallos"] },
            { name: "Mocha", parroquia: ["Mocha", "Pinguilí"] },
            {
              name: "Patate",
              parroquia: ["El Triunfo", "Los Andes", "Patate", "Sucre"],
            },
            {
              name: "Pelileo",
              parroquia: [
                "Benítez (Pachanlica)",
                "Bolívar",
                "Chiquicha",
                "Cotaló",
                "El Rosario (Rumichaca)",
                "García Moreno (Chumaqui)",
                "Guambaló (Huambaló)",
                "Pelileo",
                "Pelileo Grande",
                "Salasaca",
              ],
            },
            {
              name: "Píllaro",
              parroquia: [
                "Baquerizo Moreno",
                "Ciudad Nueva",
                "Emilio María Terán (Rumipamba)",
                "Marcos Espinel (Chacata)",
                "Píllaro",
                "Presidente Urbina (Chagrapamba - Patzucul)",
                "San Andrés",
                "San José de Poaló",
                "San Miguelito",
              ],
            },
            {
              name: "Quero",
              parroquia: ["Quero", "Rumipamba", "Yanayacu - Mochapata"],
            },
            { name: "Tisaleo", parroquia: ["Quinchicoto", "Tisaleo"] },
          ],
        },
        {
          name: "Zamora Chinchipe",
          canton: [
            {
              name: "Centinela del Cóndor",
              parroquia: ["Panguintza", "Triunfo Dorado", "Zumbi"],
            },
            {
              name: "Chinchipe",
              parroquia: [
                "Chito",
                "El Chorro",
                "La Chonta",
                "Pucapamba",
                "San Andrés",
                "Zumba",
              ],
            },
            {
              name: "El Pangui",
              parroquia: ["El Guisme", "El Pangui", "Pachicutza", "Tundayme"],
            },
            {
              name: "Nangaritza",
              parroquia: ["Guayzimi", "Nuevo Paraíso", "Zurmi"],
            },
            {
              name: "Palanda",
              parroquia: [
                "El Porvenir del Carmen",
                "La Canela",
                "Palanda",
                "San Francisco del Vergel",
                "Valladolid",
              ],
            },
            {
              name: "Paquisha",
              parroquia: ["Bellavista", "Nuevo Quito", "Paquisha"],
            },
            {
              name: "Yacuambí",
              parroquia: ["28 de Mayo", "La Paz", "Tutupali"],
            },
            {
              name: "Yantzaza",
              parroquia: ["Chicaña", "Los Encuentros", "Yantzaza"],
            },
            {
              name: "Zamora",
              parroquia: [
                "Cumbaratza",
                "El Limón",
                "Guadalupe",
                "Imbana (La Victoria de Imbana)",
                "Sabanilla",
                "San Carlos de las Minas",
                "Timbara",
                "Zamora",
              ],
            },
          ],
        },
      ],
    });
  }

  changeProvincia(event) {
	this.props.form.onChange(event)
	      this.setState({ selectedProvincia: event.target.value });
    this.setState({
      canton: this.state.provincias.find(
        (cntry) => cntry.name === event.target.value
      ).canton,
    });
  }

  changeCanton(event) {
	this.props.form.onChange(event)
    this.setState({ selectedCanton: event.target.value });
    const stats = this.state.provincias.find(
      (cntry) => cntry.name === this.state.selectedProvincia
    ).canton;
    this.setState({
      parroquia: stats.find((stat) => stat.name === event.target.value)
        .parroquia,
    });
  }

  render() {
    return (
      <div id="container">
        <div className="row">
          <div className="col-sm">
            <div>
              <label className='lbl-select'>Provincia</label>
              <select
                placeholder="Provincia"
                value={this.props.form.formValues.provincia}
                onChange={this.changeProvincia}
				name="provincia"
				className='select-ubica'
              >
                <option>--Selecciona Provincia--</option>
                {this.state.provincias.map((e, key) => {
                  return <option key={key}>{e.name}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="col-sm">
            <div>
              <label className='lbl-select'>Cantón</label>
              <select
                placeholder="State"
				name='canton'
                value={this.props.form.formValues.canton}
                onChange={this.changeCanton}
				className='select-ubica'
				
              >
                <option>--Seleccione Cantón--</option>
                {this.state.canton.map((e, key) => {
                  return <option key={key}>{e.name}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="col-sm">
            <div>
              <label className='lbl-select'>Parroquia</label>
              <select 
			  placeholder="City" 
			  className='select-ubica'
			  name='parroquia'
			  value={this.props.form.formValues.parroquia}
                onChange={this.props.form.onChange}
			  >
                <option>--Seleccione Parroquia--</option>
                {this.state.parroquia.map((e, key) => {
                  return <option key={key}>{e}</option>;
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dropdown;
