import React from "react";

import { Switch, Route, withRouter } from "react-router-dom";

import Navbar from "../components/NavBar";
import AsignarVacuna from "./AsignarVacuna";
import CertificadosReporte from "./CertificadosReporte";
import CertificadosUpload from "./CertificadosUpload";
import EstadosVacuna from "./EstadosVacuna";
import EstadoVacunaDetail from "./EstadoVacunaDetail";
import DashBoard from "./DashBoard";

import "./styles/Home.css";
import { auth, firebase } from "../firebase";

class Home extends React.Component {
  state = {
    user: {},
    userFirestore: {},
    collapse: {
      sidebar: true,
    },
  };

  handleChange = (e) => {
    this.setState({
      collapse: {
        ...this.state.collapse,
        sidebar: !this.state.collapse.sidebar,
      },
    });

    console.log(this.state.collapse.sidebar);
  };

  async componentDidMount() {
    if (auth.currentUser) {
      console.log("existe");

      const db = firebase.firestore();

      const data = await db.collection("users").doc(auth.currentUser.uid).get();

      this.setState({
        user: auth.currentUser,
        userFirestore: data.data(),
      });

      this.props.history.push("/dashboard");
    } else {
      console.log("no existe");
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-dashboard">
          <Navbar
            userName={
              this.state.userFirestore.name +
              " " +
              this.state.userFirestore.surname
            }
            userEmail={this.state.user.email}
            photo={this.state.userFirestore.photo}
            rol={this.state.userFirestore.type}
            onChange={this.handleChange}
            value={this.state.collapse}
          >
            <div
              className={
                this.state.collapse.sidebar
                  ? "col-main-content"
                  : "col-nav-content"
              }
            >
              <Switch>
                <Route path="/dashboard" exact>
                  <DashBoard
                    userName={
                      this.state.userFirestore.name +
                      " " +
                      this.state.userFirestore.surname
                    }
                  />
                </Route>

                <Route path="/asignarvacuna">
                  <AsignarVacuna
                    coordinator={this.state.userFirestore}
                    authUser={this.state.user}
                  />
                </Route>
                <Route
                  path="/certificadosReporte"
                  component={CertificadosReporte}
                  exact
                />
                <Route
                  path="/certificadosupload"
                  component={CertificadosUpload}
                />
                <Route
                  path="/estadosVacuna/:vacunaId/detail"
                  exact
                  component={EstadoVacunaDetail}
                />
                <Route path="/estadosVacuna">
                  <EstadosVacuna     coordinator={this.state.userFirestore}/>
                </Route>
            
              </Switch>
            </div>
          </Navbar>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Home);
