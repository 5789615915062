import React from "react";
import * as AiIcons from "react-icons/ai";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import "./styles/EstadoVacunaDetail.css";
import { firebase } from "../firebase";
import moment from "moment";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    height: 600,
  
    margin: "0px 20px",
    marginTop: '-57px',
    top: 143,
  
    borderRadius: 25,
  },

  title: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 10,
  },
  subtitile: {
    fontSize: 14,
    fontWeight: 400,
  },
  label: {
    fontSize: 16,
    fontWeight: 600,
    marginTop: 10,
  },
  containerProductor: {
    padding: 10,
  },
  button: {
    padding: 16,
    justifyContent: "flex-end",
    marginRight: 60,
  },
});

function EstadoVacunaDetail(props) {
  const classes = useStyles();
  const [data, setData] = React.useState({
    animals: 0,
    owner: {
      name: "",
    },
    location: {
      province: "",
      canton: "",
      parish: "",
      address: "",
    },
    detail: {
      comment: "",

      range: "",
    },
  });

  React.useEffect(() => {
    const obtenerDatos = async () => {
      const db = firebase.firestore();
      console.log("entro");
      try {
        const doc = await db
          .collection("vaccinationRequests")
          .doc(props.match.params.vacunaId)
          .get();

        if (!doc.exists) {
          console.log("No such document!");
        } else {
          console.log("Document data:", doc.data());

          setData(doc.data());
        }
      } catch (error) {
        console.log(error);
      }
    };
    obtenerDatos();
  }, [props]);

 

 

  const format_date = (value) => {
    if (value) {
      const date = new Date(value?.toDate());

      if (date) {
        return moment(String(date)).format("DD/MM/YYYY");
      }
    }
  };

  return (
    <React.Fragment>
      <div className="asigna-main">
        <div className="nav-asigna">
          <div className="detail-encabezado">
            <button  onClick={() => props.history.goBack()} className="btn btn-link btn-inicio">
              <AiIcons.AiOutlineArrowLeft /> <span>Vacunación</span>
            </button>{" "}
          </div>
        </div>

        <Card className={classes.root}>
          <CardContent>
            <div className={classes.containerProductor}>
              <h2 className={classes.title}>Datos de Solicitud</h2>
              <hr/>
              <div className="row">
                <div className="col-sm">
                  <span className={classes.subtitile}>Productor</span>
                  <h2 className={classes.label}>{data.owner.name}</h2>
                </div>
                <div className="col-sm">
                  <span className={classes.subtitile}>Numero de animales</span>
                  <h2 className={classes.label}>{data.animals}</h2>
                </div>
              </div>
            </div>

            <div className={classes.containerProductor}>
              <h2 className={classes.title}>Ubicación</h2>
              <hr/>
              <div className="row">
                <div className="col-sm">
                  <span className={classes.subtitile}>Provincia</span>
                  <h2 className={classes.label}>{data.location.province}</h2>
                </div>
                <div className="col-sm">
                  <span className={classes.subtitile}>Cantón</span>
                  <h2 className={classes.label}>{data.location.canton}</h2>
                </div>
                <div className="col-sm">
                  <span className={classes.subtitile}>Parroquia</span>
                  <h2 className={classes.label}>{data.location.parish}</h2>
                </div>
                <div className="col-sm">
                  <span className={classes.subtitile}>Dirección</span>
                  <h2 className={classes.label}>{data.location.address}</h2>
                </div>
              </div>
            </div>
            <div className={classes.containerProductor}>
              <h2 className={classes.title}>Asignación</h2>
              <hr/>
              <div className="row">
                <div className="col-sm">
                  <span className={classes.subtitile}>Fecha de asignación</span>
                  <h2 className={classes.label}>
                    {format_date(data.detail.assign)}
                  </h2>
                </div>
                <div className="col-sm">
                  <span className={classes.subtitile}>Fecha planificada</span>
                  <h2 className={classes.label}>
                    {format_date(data.detail.planed)}
                  </h2>
                </div>
                <div className="col-sm">
                  <span className={classes.subtitile}>Hora planificada</span>
                  <h2 className={classes.label}>{data.detail.range}</h2>
                </div>
                <div className="col-sm">
                  <span className={classes.subtitile}>Fecha finalizada</span>
                  <h2 className={classes.label}>
                    {format_date(data.detail.finish)}
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <span className={classes.subtitile}>Comentarios</span>
                  <h2 className={classes.label}>{data.detail.comment}</h2>
                </div>
              </div>
            </div>
          </CardContent>
       
        </Card>
      </div>
    </React.Fragment>
  );
}

export default EstadoVacunaDetail;
