import React, { useState } from "react";
import { Link,withRouter } from "react-router-dom";
import { IconContext } from "react-icons";
import { Collapse } from "react-collapse";
import {auth} from '../firebase'
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as MdIcons from "react-icons/md";
import * as GiIcons from "react-icons/gi";
import "./styles/NavBar.css";
import styled from"styled-components";
import logoAspe from "./images/log-aspe.png";


const Photo = styled.div`
display: inline-block;
width: 100px;
height: 100px;
border-radius: 50%;
margin:15px 100px;
background-repeat: no-repeat;
background-position: center center;
background-size: cover;
background-image: url('${props =>props.photo}');
max-width: 100px;
min-width: 100px;
`;


function NavBar(props) {


  const [collapse, setCollapse] = useState(false);
  const [collapse1, setCollapse1] = useState(false);

  const cerrarSesion = () => {
    auth.signOut()
        .then(() => {
            props.history.push('/')
        })
}


  return (
    <React.Fragment>
          {props.rol === 'aspe'? (
              <IconContext.Provider value={({ color: "#868686" }, { size: "1.6rem" })}>
        
              <div className={props.value.sidebar ? "col-navbar" : "col-nav"}>
                <nav className={props.value.sidebar ? "nav-menu active" : "nav-menu"}>
                  <div className={props.value.sidebar ? "cabecera" : "cabecera collapsed"}>
                    <div className="cabecera-navbar">
                      <div className="logo">
                        <img
                          className="logo-aspe"
                          src={logoAspe}
                          alt="Logo empresa ASPE"
                        ></img>
                      </div>
                      <div className="navbar-toggle" onClick={props.onChange}>
                        <Link to="#" className="menu-bars">
                          <FaIcons.FaBars />
                        </Link>
                      </div>
                    </div>
                    <div className={props.value.sidebar ? "info-user" : "info-user collapsed"}>
                      <span className="nombre-user"> {props.userName}</span>
                      <br />
                      <span className="email-user">{props.userEmail}</span>
                    </div>
                    <div className="container-photo">
                      <Photo photo={props.photo}></Photo>
                    </div>
                  </div>
                  <div
                    className={
                      props.value.sidebar ? "nav-menu-items" : "nav-menu-items collapsed"
                    }
                  >
                    <ul>
                      <li className="nav-text">
                        <span
                          className={props.value.sidebar ? "text-span" : "text-span collapsed"}
                        >
                          APLICACIÓN
                        </span>
                      </li>
                      <li className="nav-text">
                        <Link
                          to="/dashboard"
                          className={props.value.sidebar ? "" : "nav-text collapsed"}
                        >
                      
                            <MdIcons.MdDashboard aria-label="dashboard" />
                     
      
                          <span
                            className={props.value.sidebar ? "text-span" : "text-span collapsed"}
                          >
                            DashBoard
                          </span>
                        </Link>
                      </li>
                      <li className="nav-text">
                        <Link
                          to="#"
                          onClick={() => setCollapse(!collapse)}
                          className={props.value.sidebar ? "" : "nav-text collapsed"}
                        >
                      
                            <AiIcons.AiFillFile aria-label="Certificados" />
                     
                          <span
                            className={props.value.sidebar ? "text-span" : "text-span collapsed"}
                          >
                            Certificados
                          </span>
                        </Link>
                      </li>
      
                      <Collapse isOpened={collapse}>
                        <li className={props.value.sidebar ? "nav-text submenu" : "nav-text"}>
                          <Link to="/certificadosupload">
                       
                              <AiIcons.AiOutlineCloudUpload aria-label="Subir Información" />
                        
      
                            <span
                              className={
                                props.value.sidebar ? "text-span" : "text-span collapsed"
                              }
                            >
                              Subir Información
                            </span>
                          </Link>
                        </li>
                        <li className={props.value.sidebar ? "nav-text submenu" : "nav-text"}>
                          <Link
                            to="/certificadosReporte"
                            className={props.value.sidebar ? "" : "nav-text collapsed"}
                          >
                     
                              <FaIcons.FaSearch aria-label="Generar Reporte" />
                
      
                            <span
                              className={
                                props.value.sidebar ? "text-span" : "text-span collapsed"
                              }
                            >
                              Consultar Certificados
                            </span>
                          </Link>
                        </li>
                      </Collapse>
      
                      <li className="nav-text">
                        <Link
                          to="#"
                          onClick={() => setCollapse1(!collapse1)}
                          className={props.value.sidebar ? "" : "nav-text collapsed"}
                        >
                     
                            <GiIcons.GiMedicalThermometer aria-label="Vacunación" />
                 
      
                          <span
                            className={props.value.sidebar ? "text-span" : "text-span collapsed"}
                          >
                            Vacunación
                          </span>
                        </Link>
                      </li>
                      <Collapse isOpened={collapse1}>
                        <li className={props.value.sidebar ? "nav-text submenu" : "nav-text"}>
                          <Link
                            to="/asignarvacuna"
                            className={props.value.sidebar ? "" : "nav-text collapsed"}
                          >
                       
                              <AiIcons.AiFillCheckCircle aria-label="Asginar" />
                      
      
                            <span
                              className={
                                props.value.sidebar ? "text-span" : "text-span collapsed"
                              }
                            >
                              Asginar
                            </span>
                          </Link>
                        </li>
                        <li className={props.value.sidebar ? "nav-text submenu" : "nav-text"}>
                          <Link
                            to="/estadosVacuna"
                            className={props.value.sidebar ? "" : "nav-text collapsed"}
                          >
                     
                              <AiIcons.AiOutlineFileSearch aria-label="Ver estados" />
                    
      
                            <span
                              className={
                                props.value.sidebar ? "text-span" : "text-span collapsed"
                              }
                            >
                              Ver estados
                            </span>
                          </Link>
                        </li>
                      </Collapse>
                      <li className="nav-text">
                        <span
                          className={props.value.sidebar ? "text-span" : "text-span collapsed"}
                        >
                          OTRAS OPCIONES
                        </span>
                      </li>
                      <li className="nav-text">
                        <button onClick={() => cerrarSesion()} className={props.value.sidebar ? "" : "nav-text collapsed"}>
                      
                            <IoIcons.IoIosLogOut aria-label="Cerrar Sesión" />
                    
      
                          <span
                            className={props.value.sidebar ? "text-span" : "text-span collapsed"}
                          >
                            Cerrar Sesión
                          </span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </IconContext.Provider>
        ) : (
          <IconContext.Provider value={({ color: "#868686" }, { size: "1.6rem" })}>
        
          <div className={props.value.sidebar ? "col-navbar" : "col-nav"}>
            <nav className={props.value.sidebar ? "nav-menu active" : "nav-menu"}>
              <div className={props.value.sidebar ? "cabecera" : "cabecera collapsed"}>
                <div className="cabecera-navbar">
                  <div className="logo">
                    <img
                      className="logo-aspe"
                      src={logoAspe}
                      alt="Logo empresa ASPE"
                    ></img>
                  </div>
                  <div className="navbar-toggle" onClick={props.onChange}>
                    <Link to="#" className="menu-bars">
                      <FaIcons.FaBars />
                    </Link>
                  </div>
                </div>
                <div className={props.value.sidebar ? "info-user" : "info-user collapsed"}>
                  <span className="nombre-user"> {props.userName}</span>
                  <br />
                  <span className="email-user">{props.userEmail}</span>
                </div>
                <div className="container-photo">
                  <Photo></Photo>
                </div>
              </div>
              <div
                className={
                  props.value.sidebar ? "nav-menu-items" : "nav-menu-items collapsed"
                }
              >
                <ul>
                  <li className="nav-text">
                    <span
                      className={props.value.sidebar ? "text-span" : "text-span collapsed"}
                    >
                      APLICACIÓN
                    </span>
                  </li>
                  <li className="nav-text">
                    <Link
                      to="/dashboard"
                      className={props.value.sidebar ? "" : "nav-text collapsed"}
                    >
                  
                        <MdIcons.MdDashboard aria-label="dashboard" />
                 
  
                      <span
                        className={props.value.sidebar ? "text-span" : "text-span collapsed"}
                      >
                        DashBoard
                      </span>
                    </Link>
                  </li>
                  <li className="nav-text">
                    <Link
                      to="#"
                      onClick={() => setCollapse1(!collapse1)}
                      className={props.value.sidebar ? "" : "nav-text collapsed"}
                    >
                 
                        <GiIcons.GiMedicalThermometer aria-label="Vacunación" />
             
  
                      <span
                        className={props.value.sidebar ? "text-span" : "text-span collapsed"}
                      >
                        Vacunación
                      </span>
                    </Link>
                  </li>
                  <Collapse isOpened={collapse1}>
                    {props.rol === 'coordinator'? (
                      <div>
                       <li className={props.value.sidebar ? "nav-text submenu" : "nav-text"}>
                        <Link
                          to="/asignarvacuna"
                          className={props.value.sidebar ? "" : "nav-text collapsed"}
                        >
                    
                            <AiIcons.AiFillCheckCircle aria-label="Asginar" />
                    

                          <span
                            className={
                              props.value.sidebar ? "text-span" : "text-span collapsed"
                            }
                          >
                            Asginar
                          </span>
                        </Link>
                      </li>
                      <li className={props.value.sidebar ? "nav-text submenu" : "nav-text"}>
                        <Link
                          to="/estadosVacuna"
                          className={props.value.sidebar ? "" : "nav-text collapsed"}
                        >
                  
                            <AiIcons.AiOutlineFileSearch aria-label="Ver estados" />
                  

                          <span
                            className={
                              props.value.sidebar ? "text-span" : "text-span collapsed"
                            }
                          >
                            Ver estados
                          </span>
                        </Link>
                      </li>
                      </div>

                    ):(

                      <div>
                     <li className={props.value.sidebar ? "nav-text submenu" : "nav-text"}>
                       <Link
                         to="/estadosVacuna"
                         className={props.value.sidebar ? "" : "nav-text collapsed"}
                       >
                 
                           <AiIcons.AiOutlineFileSearch aria-label="Ver estados" />
                 

                         <span
                           className={
                             props.value.sidebar ? "text-span" : "text-span collapsed"
                           }
                         >
                           Ver estados
                         </span>
                       </Link>
                     </li>
                     </div>

                    )}
            
                  </Collapse>
                  <li className="nav-text">
                    <span
                      className={props.value.sidebar ? "text-span" : "text-span collapsed"}
                    >
                      OTRAS OPCIONES
                    </span>
                  </li>
                  <li className="nav-text">
                    <button onClick={() => cerrarSesion()} className={props.value.sidebar ? "" : "nav-text collapsed"}>
                  
                        <IoIcons.IoIosLogOut aria-label="Cerrar Sesión" />
                
  
                      <span
                        className={props.value.sidebar ? "text-span" : "text-span collapsed"}
                      >
                        Cerrar Sesión
                      </span>
                    </button>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </IconContext.Provider>
        )}
  
      {props.children}
    </React.Fragment>
  );
}
export default withRouter(NavBar);
