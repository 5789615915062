import React from "react";
import Clock from "../components/Clock";
import * as FaIcons from "react-icons/fa";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { firebase } from "../firebase";
import "./styles/DashBoard.css";

const useStyles = makeStyles({
  root: {
    minWidth: 150,
    borderRadius: "19px",
    width: "100%",
    marginTop: "15px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: "#868686",
  },
  indicador: {
    fontSize: 78,
    fontWeight: 600,
    color: "#2196F3",
    textAlign: "center",
  },

  label: {
    fontSize: 14,
    fontWeight: 600,
    color: "#2196F3",
    textAlign: "center",
  },

  danger: {
    color: "#F44336",
  },
  warning: {
    color: "#FF9801",
  },
  success: {
    color: "#4CAF50",
  },
  default: {
    color: "#707070",
  },
  label2: {
    fontSize: 14,

    color: "#868686",
    textAlign: "center",
  },
  pos: {
    marginBottom: 12,
  },
});

function DashBoard(props) {
  const classes = useStyles();

  const [solicitudes, setSolicitudes] = React.useState({
    total: 0,
    emergencias: 0,
    vacunacion: 0,
    vacunaSinAsignar: 0,
    vacunaPorterminar: 0,
    notifications: 0,
  });

  React.useEffect(() => {
    const obtenerDatos = async () => {
      const db = firebase.firestore();
      try {
        let data = {};
        let notifications = {};
        data = await db.collection("statistics").get();
        notifications = await db.collection("notifications").get();

        const arrayData = data.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const arrayNotifications = notifications.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        console.log(arrayData);

        let vacunaRequest = 0;
        let emergenciaRequest = 0;
        let emergenciaCompleted = 0;
        let xCompletar = 0;
        let sinAsignar = 0;
        let vacunaCompleted = 0;

        arrayData.forEach(function (estadisticas) {
          vacunaRequest = vacunaRequest + estadisticas.vaccinations.requests;
          emergenciaRequest =
            emergenciaRequest + estadisticas.emergency.requests;
          vacunaCompleted =
            vacunaCompleted + estadisticas.vaccinations.completed;
          sinAsignar =
            sinAsignar +
            (estadisticas.vaccinations.requests -
              estadisticas.vaccinations.assigned);
          emergenciaCompleted =
            emergenciaCompleted + estadisticas.emergency.completed;
          xCompletar = xCompletar + estadisticas.vaccinations.assigned;
        });

        console.log(xCompletar);
        console.log(vacunaCompleted);
        setSolicitudes({
          total:
            vacunaRequest +
            emergenciaRequest -
            (emergenciaCompleted + vacunaCompleted),
          emergencias: emergenciaCompleted,
          vacunacion: vacunaRequest - vacunaCompleted,
          vacunaSinAsignar: sinAsignar,
          vacunaPorterminar: xCompletar - vacunaCompleted,
          notifications: arrayNotifications.length,
        });
      } catch (error) {
        console.log(error);
      }
    };
    obtenerDatos();
  }, []);

  return (
    <React.Fragment>
      <div className="dashboard-main">
        <div className="nav-dashaboard">
          <h1>Bienvenido, {props.userName}.</h1>
          <h2>
            <FaIcons.FaBell />
            Tienes {solicitudes.vacunaPorterminar} pedidos de vacunación por
            completar
          </h2>
        </div>
        <div className="row margin-cards">
          <div className="col-sm">
            <Card className={classes.root}>
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  Hoy
                </Typography>
                <Typography className={classes.indicador}>
                  {solicitudes.total}
                </Typography>
                <Typography className={classes.label}>
                  Pedidos Pendientes
                </Typography>
                {/* <Typography className={classes.label2}>Atrasadas: 8</Typography>
                 */}
              </CardContent>
            </Card>
          </div>
          <div className="col-sm">
            <Card className={classes.root}>
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  Emergencias
                </Typography>
                <Typography
                  className={`${classes.indicador} ${classes.danger}`}
                >
                  {solicitudes.emergencias}
                </Typography>
                <Typography className={`${classes.label} ${classes.danger}`}>
                  Atendidas
                </Typography>
                {/*     <Typography className={classes.label2}>
                  Sin Resolver: 1
                </Typography>
        */}
              </CardContent>
            </Card>
          </div>
          <div className="col-sm">
            <Card className={classes.root}>
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  Vacunación
                </Typography>
                <Typography
                  className={`${classes.indicador} ${classes.warning}`}
                >
                  {solicitudes.vacunacion}
                </Typography>
                <Typography className={`${classes.label} ${classes.warning}`}>
                  Recibidos
                </Typography>
                {/*          <Typography className={classes.label2}>
                  Sin Asignador: {solicitudes.vacunaSinAsignar}
                </Typography>
        */}
              </CardContent>
            </Card>
          </div>
          <div className="col-sm">
            <Card className={classes.root}>
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  Notificaciones
                </Typography>
                <Typography
                  className={`${classes.indicador} ${classes.success}`}
                >
                  {solicitudes.notifications}
                </Typography>
                <Typography className={`${classes.label} ${classes.success}`}>
                  Recibida
                </Typography>
                {/*                   <Typography className={classes.label2}>Sin Leer: 8</Typography>
                 */}
              </CardContent>
            </Card>
          </div>
        </div>
        {/*                  <div className="row charts-row">
          <Card className={classes.root}>
            <CardContent>
              <div className="row">
                <div className="col-sm"></div>
                <div className="col-sm"></div>
              </div>
            </CardContent>
          </Card>
        </div>
        */}
      </div>
      <div className="dashboard-calendar">
        <Clock></Clock>
      </div>
    </React.Fragment>
  );
}

export default DashBoard;
