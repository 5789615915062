import React from "react";
import { auth } from "../firebase";
import { withRouter } from "react-router-dom";
import logoAspe from "../components/images/log-aspe.png";
import { Container } from "react-bootstrap";

const ResetPass = (props) => {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(null);

  const procesarDatos = (e) => {
    e.preventDefault();
    if (!email.trim()) {
      console.log("Datos vacíos email!");
      setError("Datos vacíos email!");
      return;
    }
    setError(null);

    recuperar();
  };

  const recuperar = React.useCallback(async () => {
    try {
      await auth.sendPasswordResetEmail(email);
      props.history.push("/");
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  }, [email, props.history]);

  return (
    <Container fluid>
      <div className="row">
        <div className="col-sm-8">
          <div className="bienvenida">
            <div className="logo">
              <img
                className="logo-aspe-inicio"
                src={logoAspe}
                alt="Logo empresa ASPE"
              ></img>
            </div>

            <div className="titulo">Bienvenido a la Aplicación PP</div>

            <div className="subtitulo">
              Administración de pedidos de vacunación, visualización de
              certificados y consolidación de información de ASPE y AGROCALIDAD.
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-login">
            <h1 className="title-login">Recuperar contraseña</h1>
            <form onSubmit={procesarDatos}>
              {error ? <div className="alert alert-danger">{error}</div> : null}
              <input
                type="email"
                className="form-control"
                placeholder="Ingrese Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />

              <div className="btn-inicio">
                <button type="submit" className="btn btn-primary">
                  Recuperar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default withRouter(ResetPass);
