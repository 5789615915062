import React from "react";
import * as FaIcons from "react-icons/fa";
import { makeStyles } from "@material-ui/core/styles";
import "./styles/CertificadosUpload.css";
import Drop from "../components/Drop";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DataGrid } from "@material-ui/data-grid";
import { firebase } from "../firebase";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
  modalHeader: {
    backgroundColor: "#148d74",
    color: "white",
  },
  modal: {
    borderRadius: 20,
    width: "100%",
    maxWidth: "700px",
  },

  content: {
    padding: "30px",
  },
  icon: {
    color: "white",
  },
}));

const stylesTable = makeStyles({
  root: {
    fontSize: "1.6rem",
    borderRadius: 25,
    backgroundColor: "white",
    border: "1px solid rgba(155, 155, 155, 1)",

    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 600,
    },
    "& .MuiDataGrid-columnsContainer": {
      borderBottom: "1px solid rgba(155, 155, 155, 1)",
    },
    "& .MuiDataGrid-row": {
      borderBottom: "2px solid rgba(155, 155, 155, 1)",
    },
  },
});

function CertificadoUpload() {
  const classes = useStyles();
  const classesTable = stylesTable();

  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState([
    {
      id: 0,
    },
  ]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const columns = [
    {
      field: "id",
      hide: true,
      identity: true,
    },
    {
      field: "certificate",
      headerName: "Número de Certificado",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "province",
      headerName: "Provincia",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "canton",
      headerName: "Cantón",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "parish",
      headerName: "Parroquia",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "ownerId",
      headerName: "Identificación",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "ownerName",
      headerName: "Nombre",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "animals",
      headerName: "# Productos Vacunados",
      flex: 1,
      type: "number",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "date",
      headerName: "Fecha Vacunación",
      type: "date",
      flex: 1,
      vaccination: "super-app-theme--header",
    },
  ];

  React.useEffect(() => {
    const obtenerDatos = async () => {
      const db = firebase.firestore();
      try {
        let data = {};

        data = await db
          .collection("certificates")
          .orderBy("vaccination", 'desc')
          .limit(10)
          .get();

        const arrayData = data.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(arrayData);

        let newData = arrayData.map(function (certificate) {
          console.log(certificate);
          const date = moment(
            certificate.vaccination.toDate().toString()
          ).format("YYYY/MM/DD");
            let parroquia='';
       
          if(certificate.parish){
            parroquia=certificate.parish
          }

          return {
            certificate: certificate.certificate,
            province: certificate.province,
            canton: certificate.canton,
            parish: parroquia,
            id: certificate.id,
            ownerId: certificate.ownerId,
            ownerName: certificate.ownerName,
            animals: certificate.animals,
            date: date,
          };
        });

        setRows(newData);
      } catch (error) {
        console.log(error);
      }
    };
    obtenerDatos();
  }, []);

  return (
    <React.Fragment>
      <div className="asigna-main">
        <div className="nav-asigna">
          <div className="asigna-encabezado">
            <h1 className={classes.icon}>
              <FaIcons.FaFileAlt aria-label="Document" /> Subir Certificados
            </h1>
          </div>
        </div>

        <div>
          <button className="btn-circle" onClick={() => handleClickOpen()}>
            <FaIcons.FaPlus />
          </button>
        </div>

        <div
          style={{
            height: 600,
            width: "100%",
            padding: "0px 25px",
            marginTop:15
     
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            checkboxSelection
            className={classesTable.root}
            sortModel={[
              {
                field: "date",
                sort: "desc",
              },
            ]}
          />
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.modal,
            container: classes.container,
          }}
        >
          <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
            Subir Certificado
          </DialogTitle>

          <DialogContent className={classes.content}>
            <Drop onClose={handleClose}></Drop>
          </DialogContent>
        </Dialog>
      </div>
    </React.Fragment>
  );
}

export default CertificadoUpload;
