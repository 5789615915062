import React, { Component } from "react";
import PageLoading from "./PageLoading";
import Dropzone from "react-dropzone";
import { firebase } from "../firebase";
import Swal from "sweetalert";
import moment from "moment";
import utf8 from "utf8";
import csv from "csv";



class App extends Component {
  state = {
    loading: false,
    error: null,
    count:0,
    file:''
  }

  onDrop(files) {
  
    this.setState({ files });
    var file = files[0];
    this.setState({ loading: true, error: null });
    const reader = new FileReader();
    reader.onload = () => {
      csv.parse(reader.result, (err, data) => {
        var userList = [];
        var count=0;
        if (data) {
          for (var i = 0; i < data.length; i++) {
            const numCertificado = data[i][0];
            const province = data[i][1];
            const canton = data[i][2];
            const parroquia = data[i][3];
            const idOwner = data[i][4];
            const nameOwner = data[i][5];
            const numVacunas = data[i][6];
            const dateVacuna = data[i][7];
            console.log(dateVacuna)
            let splitDate=dateVacuna.split('/')
      
            let date = new Date(+splitDate[2], splitDate[1]-1, +splitDate[0])
            let idCertificate=this.validaRegistro(numCertificado);
            //let date  =moment(dateVacuna).format('YYYY-MM-DD');
          
            //let dateDate.parse(cadenaFecha)
            const newCertificate = {
              animals: parseInt(numVacunas),
              canton: this.validaRegistro(canton),
              certificate: idCertificate,
              ownerId: this.validaRegistro(idOwner).trim(),
              ownerName: this.validaRegistro(nameOwner),
              parish: this.validaRegistro(parroquia),
              province: this.validaRegistro(province),
              vaccination: firebase.firestore.Timestamp.fromDate(
                new Date(date)
              ),
            };

        
          
     
            this.saveCertificate(newCertificate,idCertificate);

            userList.push(newCertificate);
            count++;
          }

      
          this.setState({ loading: false, error: null });
          this.alertaExitosa(count)
          const fileFirestore = {
            name: file.name,
            size: file.size,
            status: 'finalizado',
            type: file.type,
            dateBegin:firebase.firestore.FieldValue.serverTimestamp(),
            registrosUpload:count
          };
      
         this.saveFile(fileFirestore);
         this.props.onClose();
          
        }
      });
    };

    reader.readAsBinaryString(file);
  }

  validaRegistro(registro){
 
    if(registro){
      try {
        return utf8.decode(registro)
      } catch (error) {
        console.error(error + registro);
        return ''
      }
    
    }
    return ''
  

  }


  alertaError() {
    Swal({
      title: "Opps!",
      text: `Ha ocurrido algo inesperado 😅, vuelve a intentarlo nuevamente`,
      icon: "error",
    });
  }

  alertaExitosa(count) {
    Swal({
      title: "Carga exitosa",
      text: " Se ha completado la carga de " + count+ " registros",
      icon: "success",
    });
  }

  saveCertificate = async (newCertificate,idDoc) => {


    try {
      const db = firebase.firestore();

      await db.collection("certificates").doc(idDoc).set(newCertificate);

      this.setState({ loading: false });
  
    } catch (error) {
      this.setState({ loading: false, error: error });

      console.error(error)
    }
  };

 
  saveFile = async (file) => {
     try {
      const db = firebase.firestore();
       await db.collection("files").add(file);
    

    } catch (error) {
    }
  };

  render() {
    
    const fontSize = 5;

    return this.state.loading === false ? (
      <div align="center" oncontextmenu="return false">
        <br />
        <br />
        <br />
        <div className="dropzone">
          <Dropzone
            accept=".csv"
            onDropAccepted={this.onDrop.bind(this)}
          ></Dropzone>
          <br />
          <br />
          <br />
        </div>
        <h2>
          Sube o suelta tu
          <font size={fontSize} color="#00A4FF">
            CSV
          </font>
          <br /> aquí.
        </h2>
      </div>
    ):(
      <PageLoading />
    );
  }
}

export default App;
