import React from "react";
import { Link } from "react-router-dom";
import * as GiIcons from "react-icons/gi";
import * as FaIcons from "react-icons/fa";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { DataGrid } from "@material-ui/data-grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import DateFnsUtils from "@date-io/date-fns";
import Typography from "@material-ui/core/Typography";
import Swal from "sweetalert";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "./styles/AsignarVacuna.css";
import { firebase } from "../firebase";
import "date-fns";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "inline-block",
    alignItems: "center",
    width: "40%",
    margin: "25px 0px 25px 50px",
    borderRadius: 30,
    border: "1px solid #A9A9A9",
  },
  tooltip: {
    fontSize: 10,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: "1.8rem",
  },
  iconButton: {
    padding: 5,
    fontSize: 20,
  },

  divider: {
    height: 28,
    margin: 4,
  },
  modalHeader: {
    backgroundColor: "#148d74",
    color: "white",
  },
  modal: {
    borderRadius: 20,
    width: "100%",
    maxWidth: "700px",
  },
  reference: {
    color: "black",
    fontWeight: 600,
    fontSize: "16px",
  },
  title: {
    fontSize: "18px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  select: {
    fontSize: "14px",
  },
  content: {
    padding: "30px",
  },
  datepicker: {
    width: "100%",

    "& .MuiFormLabel-root": {
      fontSize: "17px !important",
    },
    "& .MuiInputBase-root": {
      fontSize: "14px !important",
      borderColor: "#1B9E85",
    },
  },
}));

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #1B9E85",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Nunito", "sans-serif"].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#1B9E85",
      boxShadow: "0 0 0 0.2rem #1B9E85",
    },
  },
}))(InputBase);

const CssTextField = withStyles({
  root: {
    fontSize: "1.4rem !important",
    width: "100%",

    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#CACACA",
      },

      "&.Mui-focused fieldset": {
        borderColor: "#1B9E85",
      },
    },
  },
})(TextField);

const stylesTable = makeStyles({
  root: {
    fontSize: "1.6rem",
    borderRadius: 25,
    backgroundColor: "white",
    border: "1px solid rgba(155, 155, 155, 1)",

    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 600,
    },
    "& .MuiDataGrid-columnsContainer": {
      borderBottom: "1px solid rgba(155, 155, 155, 1)",
    },
    "& .MuiDataGrid-row": {
      borderBottom: "2px solid rgba(155, 155, 155, 1)",
    },
  },
});

function AsignarVacuna(props) {
  const [rows, setRows] = React.useState([
    {
      id: 0,
    },
  ]);
  const [open, setOpen] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(Date.now());
  const [operators, setSelectOperator] = React.useState([{}]);
  const [idVacuna, setIdVacuna] = React.useState("");
  const [status, setStatus] = React.useState(0);
  const [dataForm, setDataForm] = React.useState({
    idOperator: "",
    comment: "",
    planed: "",
    range: "",
    error: null,
  });
  const classes = useStyles();
  const classesTable = stylesTable();

  const handleInputChange = (event) => {
    setDataForm({
      ...dataForm,
      [event.target.name]: event.target.value,
    });
  };

  const handleDateChange = (date) => {
    setDataForm({
      ...dataForm,
      planed: date,
    });

    setSelectedDate(date);
  };

  const handleClickOpen = async (params) => {
    setIdVacuna(params.value.id);
    setStatus(params.value.status);

    const db = firebase.firestore();
    try {
      await db
        .collection("users")
        .where("province", "==", params.value.province)
        .where("type", "==", "operator")
        .onSnapshot((snapshot) => {
          const operadores = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setSelectOperator(operadores);
        });
    } catch (error) {
      console.log(error);
    }
    setOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!dataForm.idOperator.trim()) {
      setDataForm({
        ...dataForm,
        error: "Seleccione el operador a cargo",
      });
      return;
    }

    if (!dataForm.range.trim()) {
      setDataForm({
        ...dataForm,
        error: "Seleccione un rango de hora",
      });
      return;
    }


    try {
      const db = firebase.firestore();
      const operator = operators.find((x) => x.id === dataForm.idOperator);

      await db
        .collection("vaccinationRequests")
        .doc(idVacuna)
        .update({
          responsable: {
            id: dataForm.idOperator,
            name: operator.name + " " + operator.surname,
            photo: operator.photo,
          },
          detail: {
            assign: firebase.firestore.FieldValue.serverTimestamp(),
            planed: dataForm.planed,
            finish: null,
            range: dataForm.range,
            comment: dataForm.comment,
          },
          coordinator: {
            id: props.authUser.uid,
            name: props.coordinator.name + " " + props.coordinator.surname,
            photo: props.coordinator.photo,
          },
          status: status + 1,
        });

      setDataForm({
        idOperator: "",
        comment: "",
        planed: "",
        range: "",
      });

      setSelectedDate(Date.now());
      handleClose();
      alertaExitosa();
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickTerminate =  (params) => {


    alertaTerminar(params.value.id)
   
  };

  const handleClose = () => {
    setOpen(false);
  };
  const terminate = async (id)=>{
    try {
      const db = firebase.firestore();
  
      await db
        .collection("vaccinationRequests")
        .doc(id)
        .update({
          status: 3,
          'detail.finish': firebase.firestore.FieldValue.serverTimestamp(),
        
          

        });

        Swal("Proceso de vacunación terminado con éxito", {
          icon: "success",
        });
    } catch (error) {
      console.log(error);
      Swal("Ha ocurrido un error", {
        icon: "error",
      });
    }

  }

  const columns = [
    {
      field: "id",
      hide: true,
      identity: true,
    },
    {
      field: "reference",
      headerName: "Referencia",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "productor",
      headerName: "Productor",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "idProfuctor",
      headerName: "Id Productor",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "fecha",
      headerName: "Fecha",
      type: "date",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "localidad",
      headerName: "Localidad",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "options",
      headerName: "Opciones",
      width: 150,
      renderCell: (params) => {
        if (params.value) {
          switch (params.value.status) {
            case 0:
              return (
                <strong className="btn-opciones">
                  <Tooltip
                    title="Detalles"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Link
                      to={`/estadosVacuna/${params.value.id}/detail`}
                      style={{ color: "#ffc300" }}
                      className="btn"
                    >
                      <FaIcons.FaEye />
                    </Link>
                  </Tooltip>
                  <Tooltip
                    title="Asignar"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <button
                      className="btn"
                      onClick={() => handleClickOpen(params)}
                    >
                      <FaIcons.FaUserPlus />
                    </button>
                  </Tooltip>
                </strong>
              );
            case 1:
              return (
                <strong className="btn-opciones">
                  <Tooltip
                    title="Detalles"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Link
                      to={`/estadosVacuna/${params.value.id}/detail`}
                      style={{ color: "#ffc300" }}
                      className="btn"
                    >
                      <FaIcons.FaEye />
                    </Link>
                  </Tooltip>
                  <Tooltip
                    title="Resasignar"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <button
                      className="btn"
                      onClick={() => handleClickOpen(params)}
                    >
                      <FaIcons.FaUserPlus />
                    </button>
                  </Tooltip>
                  <Tooltip
                    title="Terminar"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <button className="btn" onClick={() => handleClickTerminate(params)}>
                      <FaIcons.FaCheckCircle />
                    </button>
                  </Tooltip>
                </strong>
              );
            case 2:
              return (
                <strong className="btn-opciones">
                  <Tooltip
                    title="Detalles"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Link
                      to={`/estadosVacuna/${params.value.id}/detail`}
                      style={{ color: "#ffc300" }}
                      className="btn"
                    >
                      <FaIcons.FaEye />
                    </Link>
                  </Tooltip>
                  <Tooltip
                    title="Resasignar"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <button
                      className="btn"
                      onClick={() => handleClickOpen(params)}
                    >
                      <FaIcons.FaUserPlus />
                    </button>
                  </Tooltip>
                  <Tooltip
                    title="Terminar"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <button className="btn" onClick={() => handleClickTerminate(params)}>
                      <FaIcons.FaCheckCircle />
                    </button>
                  </Tooltip>
                </strong>
              );
            case 3:
              return (
                <strong className="btn-opciones">
                   <Tooltip
                    title="Detalles"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Link
                      to={`/estadosVacuna/${params.value.id}/detail`}
                      style={{ color: "#ffc300" }}
                      className="btn"
                    >
                      <FaIcons.FaEye />
                    </Link>
                  </Tooltip>
                </strong>
              );
            case 4:
              return (
                <strong className="btn-opciones">
                   <Tooltip
                    title="Detalles"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Link
                      to={`/estadosVacuna/${params.value.id}/detail`}
                      style={{ color: "#ffc300" }}
                      className="btn"
                    >
                      <FaIcons.FaEye />
                    </Link>
                  </Tooltip>
                </strong>
              );

            default:
              return (
                <div >
                  
                </div>
              );
            
          }
        }
      },

      disableClickEventBubbling: true,
    },
  ];

  const alertaExitosa = () => {
    Swal({
      title: "Asignación Exitosa",
      text: "Se ha asginado el operador a la solicitud de vacunación",
      icon: "success",
    });
  };

  const alertaTerminar =  (id)=>{
    Swal({
      title: "Esta seguro?",
      text: "Se procederá a dar por terminado el proceso de vacunación",
      icon: "warning",
      buttons: {
        cancel: "Cancelar",
      
        SÍ: true,
      },
      dangerMode: true,
    })
    . then((willDelete) => {
      if (willDelete) {
        terminate(id)
      
      } else {
       
      }
    });
  }

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return "Pendiente";
      case 1:
        return "Asignado";
      case 2:
        return "Reasignado";
      case 3:
        return "Terminado";
      case 4:
        return "Terminado sin Vacunar";

      default:
        return "";
    }
  };

  React.useEffect(() => {
    const obtenerDatos = async () => {
      const db = firebase.firestore();
      try {
        let data = {};

        if (props.coordinator.type === "coordinator") {
          data = await db
            .collection("vaccinationRequests")
            .where("location.province", "==", props.coordinator.province)
            .get();
        } else {
          data = await db.collection("vaccinationRequests").get();
        }

        const arrayData = data.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(arrayData);

        let newData = arrayData.map(function (vacuna) {
          let datePlaned = "";
          if (vacuna.detail?.planed) {
            datePlaned = moment(
              vacuna.detail.planed.toDate().toString()
            ).format("YYYY/MM/DD");
          }
          return {
            productor: vacuna.owner.name,
            idProfuctor: vacuna.ownerId,
            animals: vacuna.animals,
            localidad: vacuna.location.province,
            id: vacuna.id,
            fecha: datePlaned,
            reference:vacuna.serie,
            options: {
              province: vacuna.location.province,
              id: vacuna.id,
              status: vacuna.status,
            },
            status: getStatus(vacuna.status),
          };
        });

        setRows(newData);
      } catch (error) {
        console.log(error);
      }
    };
    obtenerDatos();
  }, [props]);

  return (
    <React.Fragment>
      <div className="asigna-main">
        <div className="nav-asigna">
          <div className="asigna-encabezado">
            <h1>
              <GiIcons.GiMedicalThermometer aria-label="Vacunación" />
              Asignar Vacunación
            </h1>

            <Paper component="form" className={classes.root}>
              <IconButton
                type="submit"
                className={classes.iconButton}
                aria-label="search"
              >
                <SearchIcon style={{ fontSize: 30 }} />
              </IconButton>
              <InputBase className={classes.input} placeholder="Buscar" />
            </Paper>
          </div>
        </div>
        <div
          style={{
            height: 500,
            width: "100%",
            padding: "0px 25px",
            marginTop: "-57px",
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            checkboxSelection
            className={classesTable.root}
            sortModel={[
              {
                field: 'fecha',
                sort: 'asc',
              },
            ]}
          />
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        classes={{
          paper: classes.modal,
          container: classes.container,
        }}
      >
        <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
          <Typography component={"span"} className={classes.title}>
            {status === 0 ? (
              <span>Asignar Vacunación</span>
            ) : (
              <span>Reasignar Vacunación</span>
            )}
          </Typography>
        </DialogTitle>

        <DialogContent className={classes.content}>
          <DialogContentText>
            <Typography component={"span"} className={classes.reference}>
              VAC 3242O
            </Typography>
          </DialogContentText>
          <hr />
          <form autoComplete="off" onSubmit={handleSubmit}>
            <FormControl className={classes.formControl}>
              <label className="input-select">Operador</label>
              {dataForm.error && (
                <div className="alert alert-danger"> {dataForm.error} </div>
              )}
              <Select
                native
                onChange={handleInputChange}
                className={classes.select}
                input={<BootstrapInput />}
                name="idOperator"
                value={dataForm.idOperator}
              >
                <option value="">Seleccione un operador ...</option>
                {operators.map((element) => (
                  <option key={element.id} value={element.id}>
                    {element.name + " " + element.surname}
                  </option>
                ))}
              </Select>
            </FormControl>
            <div className="form-text-area">
              <CssTextField
                id="outlined-textarea"
                label="Ind. Adicionales"
                variant="outlined"
                multiline
                rows={5}
                name="comment"
                onChange={handleInputChange}
                value={dataForm.comment}
              />
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="row margin-top">
                <div className="col-sm">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Fecha"
                    value={selectedDate}
                    onChange={handleDateChange}
                    name="date"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    className={classes.datepicker}
                  />
                </div>
                <div className="col-sm">
                  <FormControl className={classes.formControl}>
                    <label className="input-select">Hora</label>
                    <Select
                      native
                      onChange={handleInputChange}
                      className={classes.select}
                      input={<BootstrapInput />}
                      name="range"
                      value={dataForm.range}
                    >
                      <option value="">Seleccione un rango de horas ...</option>
                      <option value={"8:00 - 11:00"}>8:00 - 11:00</option>
                      <option value={"9:00 - 12:00"}>9:00 - 12:00</option>
                      <option value={"10:00 - 13:00"}>10:00 - 13:00</option>
                      <option value={"11:00 - 14:00"}>11:00 - 14:00</option>
                      <option value={"12:00 - 15:00"}>12:00 - 15:00</option>
                      <option value={"13:00 - 16:00"}>13:00 - 16:00</option>
                      <option value={"14:00 - 17:00"}>14:00 - 17:00</option>
                      <option value={"15:00 - 18:00"}>15:00 - 18:00</option>
                      <option value={"16:00 - 19:00"}>16:00 - 19:00</option>
                      <option value={"17:00 - 20:00"}>117:00 - 20:00</option>
                      
                    </Select>
                  </FormControl>
                </div>
              </div>
            </MuiPickersUtilsProvider>

            <div className="row">
              <div className="col-sm">
                <div className="btn-inicio">
                  <button type="submit" className="btn btn-primary">
                    Guardar
                  </button>
                </div>
              </div>
              <div className="col-sm">
                <div className="btn-cancelar">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleClose}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default AsignarVacuna;
